.react-calendar {
    /* width: 350px; */
    max-width: 100%;
    background: white;
    border: 0px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.09);
    margin: auto;
  }
  
  .react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    border: 0px solid rgba(160, 160, 150, 0.31);
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 0em;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
  }
  
  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    border: 0px solid rgba(160, 160, 150, 0.31);
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: rgb(75, 75, 74)
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  
  .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em;
    font-size: 14px;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 1em 0.5em;
    background: none;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  
  .react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
  .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
    display: none !important;
  }