@tailwind base;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
  font-family: 'Poppins', sans-serif;
}

input:checked ~ .dot {
    transform: translateX(150%);
    background-color: #fff;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }
 .dropdown-list {
    @apply text-[12px] before:absolute before:right-4 before:-top-[9px] before:w-4 before:h-4 before:bg-white before:rotate-45 before:border-[#ccc] before:border-l before:border-t dropdown-menu absolute bg-white border rounded-xl top-5 right-0 w-40 hidden text-gray-700 pt-1 shadow-xl;
  }
  
  .hover-div {
    @apply hover:bg-[#fff] hover:font-bold hover:text-[#0351B1]
  }


  .pulse-animation {
    @apply p-[10px] rounded-full border-2 border-[#5269c9] bg-white transition-all relative flex items-center justify-center text-thmColor w-5 h-5 cursor-pointer 
    before:content-[''] before:absolute before:-top-[2px] before:-right-[2px] before:-bottom-[2px] before:-left-[2px] before:bg-[#5269c9] before:rounded-full before:-z-[1]
    
  }
  
  


  .off-menu ul.w-full li a span, .off-menu a span, .off-menu ul.w-full h3.heading {
    display: none;
}

.off-menu {
  width: 4rem !important;
  /* transition: 0.1s all ease-in-out; */
}
.off-menu .arrowBack {
  justify-content: center;
  transform: rotate(180deg);
  border-top: none;
  border-bottom: 1px solid #fff;
}
.off-menu + div {
  padding-left: 85px;
  /* transition: 0.1s all ease-in-out; */
}

.cstm-swiper {
  position: relative;
}
.cstm-swiper .swiper-pagination {
  position: absolute;
  top: 7px;
  font-size: 12px;
  width: 50px;
  right: 130px;
  left: auto;
  height: 20px;
}

.cstm-swiper .swiper {
  padding-top: 50px
}
.cstm-swiper .swiper-button-prev, .cstm-swiper .swiper-button-next {
  position: absolute;
  top: 22px;
  right: 112px;
  left: auto;
  z-index: 99;
  height: 31px;
}
.cstm-swiper .swiper-button-next {
  right: 95px;
}

.cstm-swiper .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after, 
.cstm-swiper .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  font-size: 15px;
}

/* css of old preview start */


/* css of old preview end */



.mainLoaderNew {
  font-size: 30px;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  left: 0;
}
.mainLoaderNew1 {
  font-size: 30px;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
   background: #fff;
  top: 0;
  left: 0;
}
.mainLoaderNew.opacity {
  background: rgba(255, 255, 255, 0.5);
}

.loadingNew {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.textLoding {
  color: #4092fb;
  display: inline-block;
  margin-left: 10px;
  font-size: 20px;
  font-family: "Nunito";
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
}

.bounceball::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #4092fb;
  transform-origin: 50%;
  animation: bounce 500ms alternate infinite ease;
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}


/* .cstm-swiper .swiper-wrapper {
  transform: translate3d(0px, 0px, 0px) !important;
} */

@tailwind utilities;
.active-bg {
  @apply bg-[#fff] font-bold text-[#0351B1]
}