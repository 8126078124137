/* .edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
  } */
  
  /* .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  } */



.pulse-animation:hover:before {
    animation: pulse 1.5s infinite ease-out;
}

  @-moz-keyframes pulse {
    70%, 100% {
      top: -1.5rem;
      right: -1.5rem;
      bottom: -1.5rem;
      left: -1.5rem;
      background-color: rgba(102,215,238,0);
    }
  }
  @-webkit-keyframes pulse {
    70%, 100% {
      top: -1.5rem;
      right: -1.5rem;
      bottom: -1.5rem;
      left: -1.5rem;
      background-color: rgba(102,215,238,0);
    }
  }
  @-o-keyframes pulse {
    70%, 100% {
      top: -1.5rem;
      right: -1.5rem;
      bottom: -1.5rem;
      left: -1.5rem;
      background-color: rgba(102,215,238,0);
    }
  }
  @keyframes pulse {
    70%, 100% {
      top: -1.5rem;
      right: -1.5rem;
      bottom: -1.5rem;
      left: -1.5rem;
      background-color: rgba(102,215,238,0);
    }
  }
  
  